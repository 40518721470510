:root {
	min-height: 100vh;
	/*Primary*/
	--color-indigo: #3743aa;
	--color-white: #ffffff;
	/*Secondary*/

	--color-sunset: #f8644f;
	--color-vivere: #6cb156;

	/*Grays*/
	--color-manatee: #edf0f4;
	--color-neutral: #969ba0;
	--color-charcoal: #3e3e42;
	--color-snow: #fafafa;
	--border-radius: 4px;

	--space-1x: 8px;
	--space-2x: 16px;
	--space-4x: 32px;
	--space-7x: 56px;

	color: var(--color-charcoal);
	font-size: 14px;

	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
* {
	font-family: 'Muli', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
		'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0;
	padding: 0;
}

h1 {
	font-size: 24px;
	line-height: 32px;
}
h2 {
	font-size: 18px;
	line-height: 22px;
}
h3 {
	font-size: 16px;
	line-height: 20px;
}
p {
	font-size: 14px;
	line-height: 20px;
}
i {
	font-size: 24px;
}
a {
	text-decoration: none;
	color: var(--color-charcoal);
}
p {
	padding: var(--space-2x);
	margin: 0;
}
ul {
	padding-left: 0;
}
hr {
	border: 0;
	border-bottom: 1px solid var(--color-manatee);
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
input:not([type='radio'], [type='checkbox']),
textarea {
	width: 100%;
	border: 0;
	border-bottom: 1px solid var(--color-manatee);
	text-indent: 16px;
	min-height: 32px;
	margin-top: 16px;
}
input:focus-visible,
textarea:focus-visible {
	outline: none;
}
button {
	color: var(--color-charcoal);
}
.center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.center-x {
	display: flex;
	justify-content: center;
}
.center-y {
	display: flex;
	align-items: center;
}

.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.btn-primary {
	width: 100%;
	height: 48px;
	background: var(--color-indigo);
	border-radius: 3px;
	border: 0;
	color: white;
	font-size: 16px;
	font-weight: bold;
}
.btn-primary:disabled {
	background: var(--color-manatee);
	border: 0;
	color: var(--color-neutral);
}
.btn-outline-primary {
	width: 100%;
	height: 48px;
	font-size: 16px;
	font-weight: bold;
	background: white;
	border: 1px solid var(--color-indigo);
	color: var(--color-indigo);
	border-radius: 3px;
}
.btn-outline-primary:disabled {
	background-color: var(--color-manatee);
	color: var(--color-neutral);
	border: 0;
}
.bg-snow {
	background-color: var(--color-snow);
}
.p-16 {
	padding: 16px;
}
.m-16 {
	margin: 16px;
}
.p-x-16 {
	padding: 0 16px;
}
.p-y-16 {
	padding: 16px 0;
}
.m-x-16 {
	margin: 0 16px;
}
.m-y-16 {
	margin: 16px 0;
}
.m-b-16{
	margin-bottom: 16px;
}
.m-t-16{
	margin-top: 16px;
}
.p-b-16{
	padding-bottom: 16px;
}
.p-t-16{
	padding-top: 16px;
}

.p-b-8{
	padding-bottom: 8px;
}

.fixed-bottom {
	display: flex;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
}
.back {
	padding-left: 10px;
	padding-top: 16px;
	padding-bottom: 16px;
	display: block;
}

.bottom {
	position: fixed;
	transform: translate(-50%, -50%);
	left: 50%;
	bottom: -8px;
	width: calc(100% - 32px);
}